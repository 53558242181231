import { Card } from 'primereact/card';
import { FunctionComponent } from 'react';
import './message-center.scss';
import { ConversationMessageList } from 'concert-ui-library';
import { MessageSender, MessageSenderProps } from './message-sender';
import { Conversation } from '../../services/graphql/generated';

export interface ConversationViewerProps {
    conversation: Conversation | undefined | null;
    messageSenderComponentProps: MessageSenderProps;
}
export const ConversationViewer: FunctionComponent<ConversationViewerProps> = ({
    conversation,
    messageSenderComponentProps,
}) => {
    return (
        <Card>
            <MessageSender
                isNewConversation={messageSenderComponentProps.isNewConversation}
                handleMessageSubmission={messageSenderComponentProps.handleMessageSubmission}
                isFormVisible={messageSenderComponentProps.isFormVisible}
                disabled={messageSenderComponentProps.disabled}
            />
            <ConversationMessageList viewerType="Patient" showPatientName={false} conversation={conversation} />
        </Card>
    );
};
