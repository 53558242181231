export const PATIENT_CANNOT_BE_MESSAGED_WARNING_MESSAGE =
    'To enable Secure Messages & Resources, visit your Preferences page';
export const EPISODE_OR_OWNER_INACTIVE_WARNING_MESSAGE =
    'To re-connect with Concert services, call us at (855) 641-6060';

export const PATIENT_CANNOT_BE_MESSAGED_ERROR_MESSAGE =
    'Message not saved. To enable Secure Messages & Resources, visit your Preferences page';
export const EPISODE_OR_OWNER_INACTIVE_ERROR_MESSAGE =
    'Message not saved. To re-connect with Concert services, call us at (855) 641-6060';

export const ERROR_CODES = {
    PATIENT_CANT_RECEIVE_COMMUNICATIONS: '-0001',
    INACTIVE_EPISODE: '-0002',
    INACTIVE_EPISODE_OWNER: '-0004',
};
